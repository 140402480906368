/* ---------------------------------------------------------------------
Variables
------------------------------------------------------------------------ */

$backgroundColor: #ffffff;
$headerFontColor: #455873;
$bodyFontColor: #919599;
$bodySecondaryFontColor: #1a1a1a;
$accentColor: #e5746e;
$headerFont: "din_alternatebold", sans-serif;
$bodyFont: Source Sans Pro, sans-serif;

/* ---------------------------------------------------------------------
Global Styles
------------------------------------------------------------------------ */

body {
  background: $backgroundColor;
  margin: 0;
}

section {
  padding: 0 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  font-family: $bodyFont;
  color: $bodyFontColor;
  font-size: 21px;
  line-height: 30px;
  font-weight: 400;
}

hr {
  height: 3px;
  width: 55px;
  background-color: $accentColor;
  border: 0;
}

.hr-secondary {
  height: 1px;
  width: 100%;
  background-color: #d8d8d8;
}

a {
  text-decoration: none;
  color: inherit;
}

.anchor-inline {
  text-decoration: underline;
}

.anchor-blog {
  transition-property: color;
  transition-duration: 0.25s;
  &:hover {
    color: $accentColor;
  }
}

.anchor-white {
  color: #ffffff;
  transition-property: color;
  transition-duration: 0.25s;
  &:hover {
    color: $accentColor;
  }
}

.anchor-red {
  color: $accentColor;
  transition-property: color;
  transition-duration: 0.25s;
  &:hover {
    color: darken($accentColor, 20);
  }
}

.lg-container {
  max-width: 1040px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.md-container {
  max-width: 860px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.tan-background {
  background-color: #f8f6f3;
}

.blue-background {
  background-color: #5a6b83;
}

.red-background {
  background-color: $accentColor;
}

.teal-background {
  background-color: #6e929e;
}

.blue-background,
.red-background,
.teal-background {
  h2 {
    color: #ffffff;
  }
  p {
    color: #ffffff;
  }
}

/* ---------------------------------------------------------------------
Type Styles
------------------------------------------------------------------------ */

@font-face {
  font-family: "din_alternatebold";
  src: url("../fonts/din-alternate-bold/din-alternate-bold-webfont.woff2")
      format("woff2"),
    url("../fonts/din-alternate-bold/din-alternate-bold-webfont.woff")
      format("woff"),
    url("../fonts/din-alternate-bold/din-alternate-bold-webfont.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

h1 {
  font-family: $headerFont;
  color: $headerFontColor;
  font-size: 36px;
  line-height: 48px;
  font-weight: normal;
  @media screen and (max-width: 900px) {
    font-size: 32px;
    line-height: 42px;
  }
}

h2 {
  font-family: $headerFont;
  color: $headerFontColor;
  font-size: 32px;
  line-height: 42px;
  font-weight: normal;
}

h3 {
  font-family: $headerFont;
  color: $headerFontColor;
  font-size: 21px;
  line-height: 30px;
  font-weight: normal;
}

p {
  font-family: $bodyFont;
  color: $bodyFontColor;
  font-size: 21px;
  line-height: 30px;
  font-weight: 400;
}

.body-small {
  font-family: $bodyFont;
  color: #ffffff;
  font-size: 17px;
  line-height: 24px;
  font-weight: 400;
}

.body-small-secondary {
  opacity: 0.7;
}

.body-meta {
  font-family: $bodyFont;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}

.heading {
  font-family: $headerFont;
  font-size: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.red-text {
  color: $accentColor;
}

.white-text {
  color: #ffffff;
}

.black-text {
  color: $bodySecondaryFontColor;
}

/* ---------------------------------------------------------------------
Header
------------------------------------------------------------------------ */

header {
  background-color: #f8f6f3;
  width: 100%;
}

.nav-wrapper {
  max-width: 1156px;
  margin: 0 auto;
  padding: 44px 20px 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-item {
  display: inline-block;
  margin-right: 40px;
  &:last-of-type {
    margin-right: 0;
  }
  @media screen and (max-width: 500px) {
    margin-right: 10px;
  }
}

.nav-link {
  font-size: 17px;
  color: #1a1a1a;
  transition-property: color;
  transition-duration: 0.25s;
  &:hover {
    color: $accentColor;
  }
}

.logo {
  max-width: 160px;
  transition-property: opacity;
  transition-duration: 0.25s;
  &:hover {
    opacity: 0.5;
  }
}

/* ---------------------------------------------------------------------
Footer
------------------------------------------------------------------------ */

footer {
  background-color: #1a1a1a;
  padding: 0 20px;
}

.footer-wrapper {
  padding: 84px 0 240px 0;
}

.footer-left-wrapper {
  display: flex;
  align-items: flex-start;
}

.footer-icon {
  width: 62px;
}

.footer-links-wrapper {
  margin-left: 68px;
}

.footer-links-wrapper > .heading {
  margin-bottom: 30px;
}

.footer-links-wrapper li {
  margin-bottom: 24px;
}

.footer-link {
  font-size: 17px;
  color: #ffffff;
  transition-property: color;
  transition-duration: 0.25s;
  &:hover {
    color: $accentColor;
  }
}

.social-row {
  text-align: right;
  margin-bottom: 40px;
}

.social-row > a {
  margin-left: 30px;
}

#social-icon-fill {
  transition-property: fill;
  transition-duration: 0.25s;
}

.social-icon:hover #social-icon-fill {
  fill: $accentColor;
}

@media screen and (max-width: 500px) {
  footer .lg-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer-left-wrapper {
    flex-direction: column;
  }
  .footer-links-wrapper {
    margin-left: 0;
    margin-top: 40px;
  }
  .social-row {
    margin-top: 50px;
    text-align: left;
  }
  .social-row > a {
    margin-left: 0;
    margin-right: 30px;
  }
}

/* ---------------------------------------------------------------------
Global Styles - Index
------------------------------------------------------------------------ */

.content-block-1 {
  max-width: 420px;
}

.content-block-1 > .heading {
  margin: 10;
}

.content-block-1 > h1 {
  margin: 0 0 20px 0;
}

.content-block-1 > h2 {
  margin: 0 0 20px 0;
}

.content-block-1 > .button-wrapper {
  margin-top: 50px;
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    height: 150px;
  }
}

.app-store-button {
  @media screen and (min-width: 901px) {
    height: 64px;
  }
  @media screen and (max-width: 900px) {
    width: 200px;
  }
}

.hero-illustration,
.progress-illustration,
.packs-illustration {
  max-width: 360px;
  @media screen and (max-width: 900px) {
    max-width: 300px;
  }
}

/* ---------------------------------------------------------------------
Hero Section - Index
------------------------------------------------------------------------ */

.index-hero-section {
  padding-bottom: 100px;
}

.index-hero-section .content-block-1 {
  margin-top: 100px;
}

.hero-illustration-wrapper {
  width: 40%;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .index-hero-section > .lg-container {
    flex-direction: column;
    align-items: center;
  }

  .index-hero-section .content-block-1 {
    margin-top: 0px;
  }

  .hero-illustration-wrapper {
    width: 100%;
  }
}

/* ---------------------------------------------------------------------
Benefits Section - Index
------------------------------------------------------------------------ */

.benefits-section {
  padding-top: 100px;
  padding-bottom: 160px;
}

.benefits-container {
  align-items: flex-end;
}

.benefits-content-wrapper {
  max-width: 310px;
}

.benefits-illustration-wrapper {
  min-height: 237px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.benefits-illustration {
  max-width: 240px;
}

.content-block-2 {
  text-align: center;
}

.content-block-2 > h3 {
  margin: 40px 0 0 0;
}

.content-block-2 > p {
  margin-top: 16px;
}

@media screen and (max-width: 900px) {
  .benefits-section {
    padding-top: 20px;
    padding-bottom: 60px;
  }
  .benefits-container {
    flex-direction: column;
    align-items: center;
  }
}

/* ---------------------------------------------------------------------
Vocabulary Section - Index
------------------------------------------------------------------------ */

.vocabulary-section {
  box-shadow: inset 0px -120px 0px 0px #ffffff, inset 0px 120px 0px 0px #ffffff;
}

.vocabulary-container {
  align-items: center;
}

@media screen and (max-width: 900px) {
  .vocabulary-section {
    box-shadow: none;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .vocabulary-container {
    flex-direction: column;
    align-items: center;
    flex-flow: column-reverse;
  }
}

/* ---------------------------------------------------------------------
Testimonials Section - Index
------------------------------------------------------------------------ */

.testimonial-section {
  text-align: center;
  padding-top: 140px;
  padding-bottom: 110px;
}

.testimonial-card-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.testimonial-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  padding: 32px 30px 40px 32px;
  width: 275px;
  max-height: 280px;
  margin: 0 8px 0 8px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
}

.testimonial-card > .body-small {
  margin-top: 24px;
}

.testimonial-details-wrapper {
  margin-top: 32px;
  padding-left: 14px;
  border-left: 2px solid white;
}

.testimonial-details-wrapper > .heading {
  margin-top: 0px;
  margin-bottom: 8px;
}

.testimonial-details-wrapper > .body-small-secondary {
  margin: 0;
  line-height: 15px;
}

.product-hunt-button {
  margin-top: 40px;
}

@media screen and (max-width: 900px) {
  .testimonial-section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .testimonial-card-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .testimonial-card {
    margin-top: 20px;
  }
}

/* ---------------------------------------------------------------------
Progress Section - Index
------------------------------------------------------------------------ */

.progress-section {
  box-shadow: inset 0px -120px 0px 0px #ffffff, inset 0px 120px 0px 0px #ffffff;
}

.progress-container {
  align-items: center;
}

@media screen and (max-width: 900px) {
  .progress-section {
    box-shadow: none;
  }
  .progress-container {
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

/* ---------------------------------------------------------------------
Exit Row - Index
------------------------------------------------------------------------ */

.exit-row-section {
  text-align: center;
  padding-top: 120px;
  padding-bottom: 175px;
}

.exit-row-section > p {
  margin-bottom: 50px;
}

.exit-row-section > .button-wrapper {
  max-width: 420px;
  margin: 0 auto;
}

@media screen and (max-width: 900px) {
  .exit-row-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

/* ---------------------------------------------------------------------
Content Page Styles
------------------------------------------------------------------------ */

.content-hero-section {
  padding-top: 170px;
  padding-bottom: 140px;
  position: relative;
  @media screen and (max-width: 900px) {
    padding-top: 110px;
    padding-bottom: 60px;
  }
}

.content-container {
  max-width: 860px;
  margin: 0 auto;
  padding-top: 70px;
  padding-bottom: 80px;
  @media screen and (max-width: 900px) {
    padding-top: 50px;
    padding-bottom: 60x;
  }
}

.content-container h3 {
  margin-top: 40px;
}

.character-bg {
  max-width: 200px;
  position: absolute;
  bottom: 0;
  right: 350px;
  @media screen and (max-width: 900px) {
    max-width: 120px;
    right: 50px;
  }
}

.post-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 40px;
  @media screen and (max-width: 900px) {
    flex-direction: column-reverse;
  }
}

.post-wrapper:not(:last-child) {
  margin-bottom: 80px;
  @media screen and (max-width: 900px) {
    margin-bottom: 50px;
  }
}

.post-wrapper-content {
  max-width: 420px;
  @media screen and (max-width: 900px) {
    max-width: 100%;
  }
}

.post-wrapper hr {
  margin: 0;
  @media screen and (max-width: 900px) {
    margin-top: 24px;
  }
}

.post-wrapper h3 {
  margin-top: 20px;
}

.post-image {
  height: 200px;
  width: 350px;
  background-position: center;
  background-size: cover;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
}

/* ---------------------------------------------------------------------
Post Page Styles
------------------------------------------------------------------------ */

.post-hero-section {
  padding-top: 40px;
  padding-bottom: 50px;
  @media screen and (max-width: 900px) {
    padding-top: 10px;
  }
}

.post-body-section {
  padding-top: 66px;
  padding-bottom: 60px;
  @media screen and (max-width: 900px) {
    padding-bottom: 40px;
  }
}

.post-hero-container,
.post-body-container,
.post-additional-resources-container {
  max-width: 686px;
  margin: 0 auto;
}

.post-hero-image {
  height: 400px;
  width: 100%;
  background-position: center;
  background-size: cover;
  @media screen and (max-width: 900px) {
    height: 220px;
  }
}

.post-body-container img {
  width: 100%;
}

.post-body-container h2 {
  margin-top: 40px;
}

.post-body-container p img {
  margin-top: 20px;
  margin-bottom: 20px;
}

.post-body-container hr {
  margin-bottom: 40px;
  margin-left: 0;
}

blockquote {
  border-left: 3px solid $accentColor;
  margin-inline-start: 0;
  padding-inline-start: 20px;
}

blockquote p {
  font-style: italic;
}

.post-body-container p a {
  text-decoration: underline;
  color: $accentColor;
  transition-property: color;
  transition-duration: 0.25s;
  &:hover {
    color: darken($accentColor, 20);
  }
}

// Additional Reading Overrides
.post-additional-resources-section {
  padding-bottom: 80px;
  @media screen and (max-width: 900px) {
    padding-bottom: 20px;
  }
}

.readings-wrapper-content {
  max-width: 320px;
  @media screen and (max-width: 900px) {
    max-width: 100%;
  }
}

.readings-image {
  height: 170px;
  width: 290px;
  background-position: center;
  background-size: cover;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
}

// Tones Post
.tone-container .tone-wrapper {
  margin-bottom: 32px;
}

.tone-wrapper p {
  margin-bottom: 8px;
  color: $bodySecondaryFontColor;
}

/* ---------------------------------------------------------------------
Email Signup Form
------------------------------------------------------------------------ */

.email-signup-section {
  padding-top: 70px;
  padding-bottom: 100px;
  @media screen and (max-width: 900px) {
    padding-top: 40px;
    padding-bottom: 70px;
  }
}

.email-signup-container {
  max-width: 686px;
  margin: 0 auto;
}

// Column is reversed so that the focus attribute on the input field can apply to the field label
.mc-field-group {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 40px;
}

.mc-field-group label {
  margin-bottom: 10px;
}

#mc_embed_signup_scroll h2 {
  margin-bottom: 0px;
}

#mc_embed_signup_scroll p {
  margin-bottom: 24px;
}

.text-input {
  font-family: $bodyFont;
  color: #ffffff;
  font-size: 21px;
  line-height: 30px;
  font-weight: 400;
  border-style: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition-property: border-bottom;
  transition-duration: 0.25s;
  &:focus {
    outline: none !important;
    border-bottom: 1px solid rgba(255, 255, 255, 1);
  }
  &:focus + .input-label {
    color: rgba(255, 255, 255, 1);
  }
}

.input-label {
  color: rgba(255, 255, 255, 0.5);
  transition-property: color;
  transition-duration: 0.25s;
}

.button {
  background-color: #ffffff;
  color: #1a1a1a;
  border-style: none;
  border: 0;
  width: 100%;
  padding: 20px 0 20px 0;
  font-family: $headerFont;
  font-size: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  transition-property: background-color;
  transition-duration: 0.25s;
  cursor: pointer;
  &:hover {
    background-color: darken(#ffffff, 10);
  }
}
